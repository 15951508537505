import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "style/global.styled";
import styled, { css } from "styled-components";
import { TextField } from "components/utils";
import { Button } from "style/global.styled";
import DatePicker from "components/utils/date-picker";
import { Selector } from "components/utils";
import { ErrorMsg } from "style/global.styled";
import moment from "moment";
import { sendReservation } from "utils/dataHandler";
import useFormValues from "hooks/useFormValues";
import { NotificationContext } from "contexts/NotificationContext";
import { Text } from "style/global.styled";

const Cont = styled(Container)`
min-height: 70vh;
display: flex;
position: relative;
text-align: center;
flex-direction: row;
padding: 50px 40px;
@media (max-width: 1000px) {
}
@media (max-width: 1000px) {
  flex-direction: column;
  padding: 20px 15px;
}
`;

const Selectors = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > :nth-child(1) {
    width: 310px;
    margin-right: 10px;
  }
  & > :nth-child(2) {
    width: 180px;
    margin-right: 10px;
  }
  @media (max-width: 600px) {
    & > :nth-child(1),
    & > :nth-child(2) {
      width: 100%;
      margin-right: 10px;
    }
  }
`;

const StyledDiv = styled.div`
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20px auto;
`

const Error = styled(ErrorMsg)`
  display: ${props => !props.error && "none"};
  margin-bottom: 10px;
`;

const dateFormat = "yyyy-MM-dd";

const minDate = () => {
  const now = new Date()
  now.setDate(now.getDate() + 1)
  return now
}

const initalFormValues = { 
  name: "",
  email: "",
  mobile: "",
  note: "",
  people: "",
  duration: "",
}

function Reservation() {
  const [date, setDate] = useState(minDate());
  const [time, setTime] = useState(minDate());
  const [error, setError] = useState(false);
  const [formValues, changeHandler, setFormValues] = useFormValues(initalFormValues)

  const { notificationModels } = useContext(NotificationContext);

  const endDate = () => {
    let endDate = new Date(date)
    endDate.setHours(endDate.getHours() + Number(formValues.duration))
    return endDate
  }

  const maximumNumberOfPeople = 15
  const maximumDuration = 3

  const loq_location_id = '139188405'
  const loq_api_key = 'tableReservationApiKey'

  function reservationData() {
    return {
      name: formValues.name,
      phoneNumber: formValues.mobile,
      email: formValues.email,
      fromDate: new Date(date),
      toDate: new Date(endDate()),
      seats: formValues.people,
      comment: formValues.note,
      locationId: loq_location_id,
      sendEmail:!0,
      apiKey:loq_api_key
    };
  }

  const bookingButtonDisabled = () => {
    return formValues.people === initalFormValues.people || formValues.name === initalFormValues.name || formValues.mobile === initalFormValues.mobile || formValues.email === initalFormValues.email || formValues.duration === initalFormValues.duration
  }

  const handleSubmit = e => {
      e.preventDefault();
      sendReservation(reservationData())
      .then( response => {
        setFormValues(initalFormValues);
        notificationModels.successReservation();
      })
      .catch(err => {
        notificationModels.reservationError()
      });

    };

  const handleChange = e => {
    if (error) setError("");
    changeHandler(e);
  };

  const handleDateChange = date => {
    setDate(date);
  };

  const { t: translate } = useTranslation();

  const handleTimeChange = e => {
      setTime(new Date(e.target.value))
      let reservationDate = new Date(date)
      let reservationTime = new Date(e.target.value)
      reservationDate.setHours(reservationTime.getHours())
      reservationDate.setMinutes(reservationTime.getMinutes())
      setDate(reservationDate)
    };

    const generateTimes = () => {
      const times = [];
      const comparingHours = date.getHours() + 2;
  
      const date2 = new Date();
      date2.setHours(comparingHours);
  
      const lastDate = new Date(date);
      lastDate.setHours(22);
      lastDate.setMinutes(0);
  
      for (let i = 12; i <= 22; i++) {
        if (comparingHours <= i || date.getDate() > date2.getDate()) {
          for (let j = 0; j <= 45; j += 15) {
            const compareDate = new Date(date);
            compareDate.setHours(i);
            compareDate.setMinutes(j);
            if (date2 < compareDate && compareDate <= lastDate) {
              times.push(compareDate);
            }
          }
        }
      }
      return times;
    };

return (
  <Cont>
      <StyledDiv>
          <form onSubmit={handleSubmit}>
              <StyledDiv>
                  <TextField
                    type="text"
                    name="name"
                    label= {translate('reservation.name')}
                    onChange = {handleChange}
                    value={formValues.name}
                    error={!!error}
                    required
                  />
                  <Selectors>
                  <Text>{translate('reservation.date')}</Text>
                  <DatePicker
                      format={dateFormat}
                      minDate = {minDate()}
                      value = { date }
                      onChange={handleDateChange}
                      error={error}
                  />
                  </Selectors>
                  <Selectors>
                  <Selector 
                  name="time" 
                  onChange={handleTimeChange}
                  label= {translate('reservation.when')}
                  required
                   >
                    { generateTimes().map(time => (
                      <option key={time} value={time}>
                        {moment(time).format("H:mm")}
                     </option>
                        )
                      )
                    }
                  </Selector>
                  <Selector 
                  name="people"
                  onChange={handleChange}
                  label= {translate('reservation.numberOfPeople')}
                  required
                  error={true}
                  value={formValues.people}
                   >
                    { [...Array(maximumNumberOfPeople).keys()].map(people => (
                      <option key={people + 1} value={people + 1}>
                        {people + 1}
                     </option>
                        )
                      )
                    }
                  </Selector>
                  <Selector 
                  name="duration"
                  onChange={handleChange}
                  label= {translate('reservation.duration')}
                  value={formValues.duration}
                  required
                  error={true}
                   >
                    { [...Array(maximumDuration).keys()].map(hour => (
                      <option key={hour + 1} value={hour + 1}>
                        {hour + 1 + translate('reservation.hour')}
                     </option>
                        )
                      )
                    }
                  </Selector>
                  </Selectors>
                  <TextField
                    name="note"
                    label= {translate('reservation.note')}
                    value= {formValues.note}
                    onChange = {handleChange}
                  />
                  <TextField
                    type = "tel"
                    name="mobile"
                    label= {translate('reservation.phone')}
                    onChange = {handleChange}
                    value={formValues.mobile}
                    error={!!error}
                    required
                  />
                  <TextField
                    type="text"
                    name="email"
                    label= {translate('reservation.email')}
                    onChange = {handleChange}
                    value={formValues.email}
                    error={!!error}
                    required
                  />
                  <Error error={error}>{translate(`error.${error}`)}</Error>
                  <Button type="submit" disabled={ bookingButtonDisabled() }>{translate('reservation.reserve')}</Button>
              </StyledDiv>
          </form>
      </StyledDiv>
  </Cont>
)
}

export default Reservation